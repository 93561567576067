<template>
  <div>
    <table-compnent
      :records="tableData"
      @resetHandler="reset"
      @searchHandler="doSearch"
      @addHandler="saveRole"
      @queryByIdHandler="queryById"
      @deleteByIdHandler="deleteById"
      :showDelete="false"
      :showUpdate="false"
      :loading="loading"
    >
      <template v-slot:search>
        <el-form-item label="角色名称：" class="rig">
          <el-input
            v-model="search.name"
            placeholder="请输入角色名称"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:content>
        <el-table-column prop="name" label="角色名称">
          <template slot-scope="scope">
            <el-button type="text" @click="toDetail(scope.row.id)">{{
              scope.row.name
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="description" label="角色描述"></el-table-column>
        <el-table-column label="操作" width="120">
          <template scope="s">
            <el-button
              type="text"
              size="small"
              @click="queryById(s.row.id, s.row.type)"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="toDetail(s.row.id)"
              >查看权限</el-button
            >

            <el-button
              type="text"
              size="small"
              @click="deleteById(s.row.id)"
              v-if="s.row.hasDeleteFlag != 0"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </template>
    </table-compnent>
  </div>
</template>

<script>
import TableCompnent from "@/components/table/TablesComponents";
import RoleApi from "@/api/RoleApi";
import { navTo, queryParam } from "@/utils/NavigatorUtils";
export default {
  name: "RoleList",
  props: {
    msg: String,
  },
  components: {
    TableCompnent,
  },
  data() {
    return {
      tableData: {},
      search: {
        name: "",
      },
      page: {},
      loading: true,
    };
  },
  methods: {
    reset() {
    this.search = this.$options.data().search;
      this.doSearch({ pageSize: 20, pageNum: 1 });
    },
    doSearch(params) {
      this.page = params;
      this.search.pageSize = this.page.pageSize;
      this.search.pageNum = this.page.pageNum;
      this.loading = true;
      RoleApi.search(this.search).then((resp) => {
        if (resp.code == "200") {
          this.tableData = resp.data;
          this.loading = false;
        }
      });
    },
    toDetail(id) {
      this.$router.push({
        name: "RoleDetail",
        params: {
          id,
        },
      });
    },
    saveRole() {
      navTo(this, "/saveRole");
    },
    queryById(id, type) {
      this.$router.push({
        name: "saveRole",
        query: {
          id,
          type,
        },
      });
    },
    deleteById(id) {
      this.$confirm("此操作将永久删除该角色, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        RoleApi.deleteRole({ id }).then((resp) => {
          if (resp.code == "200") {
            this.doSearch(this.page);
            this.$successMsg(resp.msg);
          } else {
            this.$errorMsg(resp.msg);
          }
        });
      });
    },
    dispatcherPermission(roleId) {
      queryParam(this, "dispatcherPermission", { roleId: roleId });
    },
  },
};
</script>
<style scoped>
.rig {
  margin-right: 60px;
}
</style>